/*---------------------------------------------------------------
    Related Products
--------------------------------------------------------------- */

.related-products.related-products {
    background: linear-gradient(112deg, rgba($light-grey, 0.5) 0%, rgba($light-grey, 0.1) 100%);

	@include bp1200 {
		padding: 50px 20px;
		float: left;
		width: 100%;

	}

	h3 {
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: 20px;
	}

	a {
		text-decoration: none;
		display: block;

		&:hover {
			filter: contrast(1.4);
			.title {
				background: $primary-color;
				color: $blanc;
			}
			ul {
				color: $primary-color--dark;
			}
		}
	}
	.title {
		color: $primary-color;
		font-size: 1.1em;
		transition: .3s;
		padding: 10px;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-align: center;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: $light-grey;
	}
	ul  {
		list-style: none;
		color: $noir;
		font-size: 0.8em;
		padding: 10px 0;
	}
	.offer {
		padding: 10px;
		color: $blanc;
		font-family: $primary-font;
		display: block;
		@include primaryGradient;
		text-align: center;
		font-weight: 400;
		@include flexbox();
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}

.related-products__single-item {
	position: relative;
	position: relative;
	border: 10px solid $blanc;
	box-shadow: 0 1px 15px rgba($noir, .1);
	margin-bottom: 20px;
}
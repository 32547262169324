.cta--visualise-your-room {
    width: 100%;
    background: $light-grey;
    text-align: center;
    text-decoration: none;
    padding: 30px;

    img {
        max-width: 100px;
        margin-bottom: 30px;
    }

    a {
        text-decoration: none;
    }

    .text {
        font: 1.8rem/1.2 $primary-font;
        color: $secondary-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 30px;

    }

    .btn {
        font-size: 1.6rem;
        font-family: $highlight-font;
        margin-right: 0;
    }

    @include bp800 {
        margin-top: 50px;
        padding: 50px 20px;

        .text {
            font-size: 3rem;
            max-width: 400px;
            margin: 0 auto 30px;
        }

    }
}
.related-services {
    background: $secondary-color;

    &__title {
        color: $blanc;
        font-size: 1.2em;
        font-weight: 600;
        margin: 0 0 1em;
        text-align: center;
        text-transform: uppercase;
        
        @include bp480 {
            text-align: left;
        }
    }
}
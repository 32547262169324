.home-visit-outer {
    background: linear-gradient(160deg, rgba($primary-color, 0.2) 15%, rgba($primary-color, 0.1) 85%);
    text-align: center;

    @include bp800 {
        .grid12_12 {
            @include flexbox;
            @include align-items(center);
        }
    }

    h2 {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $secondary-color;
        font-family: $highlight-font;

        span {
          display: block;
        }
    }

    .ld-phonenumber {
        font-size: 3rem;
        a {
            color: $tertiary-color;
            text-decoration: none;
            font-weight: 700;
        }

        @include iPhone5 {
          font-size: 2.8rem;
        }
    }

    .container {
        max-width: 700px;
    }

    form {
        max-width: 370px;
        display: block;
        margin: 30px auto 10px;

        input {
            border: 2px solid $tertiary-color!important;
            font-size: 2rem;

            &[type=submit], &[type=button] {
                width: 100%;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding: 15px 15px 12px;
                color: $blanc;
            }
        }
    }

    @include bp(393) {
      h2 {
        span {
          display: inline-block;
        }
      }
    }

    @include bp800 {
        h2 {
            font-size: 3.5rem;
        }
    }

    @include bp1000 {
      h2 {
        span {
          display: inline;
        }
      }
    }

    @include bp1200 {
        padding: 50px;

        .ld-phonenumber {
            font-size: 4rem;
        }

        form {
            margin-top: 50px;
        }
    }

    @include bp1400 {
      h2 {
        span {
          display: block;
        }
      }
    }
}

.home-visit-steps {
    background: linear-gradient(112deg, rgba($light-grey, 0.5) 0%, rgba($light-grey, 0.1) 100%);
    text-align: center;

    @include bp800 {
        text-align: left;
        @include justify-content(center);
    }

    h3 {
        color: $primary-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
    }

    ul {
        margin: 20px 0;
    }

    li {
        list-style: none;
        padding: 10px 0;
        color: $secondary-color;

        svg {
            width: 50px;
            height: 50px;
            color: $primary-color;
            margin-bottom: 10px;
        }

        span {
            display: block;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 800;
            margin-bottom: 10px;
        }
    }

    @include bp800 {
        h3 {
            font-size: 3.3rem;
            margin-bottom: 30px;
        }

        li {
            position: relative;
            padding: 10px 0 10px 80px;
            margin-bottom: 50px;

            &:last-of-type { margin-bottom: 0; }

            svg {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    @include bp1200 {
        padding: 30px;

        h3 {
            margin-bottom: 50px;
        }
        li {
            max-width: 600px;

            &:before {
                content: "";
                position: absolute;
                top: 65px;
                left: 23px;
                width: 2px;
                height: 80px;
                background: linear-gradient(120deg, rgba($secondary-color, 1) 0%, rgba($secondary-color, 0) 100%);
            }

            &:last-of-type:before {
                display: none;
            }

            span {
                font-size: 2.2rem;
            }
        }
    }
}

// --------------------------------------------------------------------------------------------------
// Blinds Range Tabs
// --------------------------------------------------------------------------------------------------

.blinds-range-tabs {
    @include bp1000 {
        margin-bottom: 80px;
    }
}

.blinds-range-tabs-header {
    background: $light-grey;
    text-align: center;
    padding: 5vw 15px;

    @include bp800 {
        padding: 5vw 0;
    }

    @include bp1400 {
        padding: 60px 0;
    }

    h3 {
        margin-bottom: 8px;
        font-size: 2.8rem;
        position: relative;

        @include bp600 {
            font-size: 3.5rem;
        }

        @include bp800 {
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: calc(50% - 200px);
                height: 1px;
                background: #cdd1d4;
            }

            &:after {
                right: 0;
                left: auto;
            }
        }

        @include bp1000 {
            font-size: 5rem;

            &:before,
            &:after {
                width: calc(50% - 340px);
            }
        }

        @include bp1400 {
            font-size: 6rem;
        }
    }

    p {
        max-width: 750px;
        color: #6b6f7a;
        font-size: 1.6rem;
        line-height: 1.4;
        margin: 0 auto;

        @include bp1000 {
            font-size: 1.8rem;
        }
    }
}

.blinds-range-tabs-main {
    @include bp800 {
        @include flexbox;
    }
}

.blinds-range-tabs-content {
    .text {
        padding: 20px;
    }
    .excerpt {
        display: none;
    }
    .blinds-image {
        float: left;
        width: 100%;
        height: 200px;
        background-position: center;
    }

    .ld-phonenumber {
      display: inline-block;
    }

    @include bp800 {
        @include flexbox;
        width: 70%;

        .excerpt {
            display: block;
        }

        .text {
            float: left;
            width: 50%;
            @include flexbox;
            @include flex-wrap(wrap);
            @include align-content(center);
            padding: 40px;
        }

        .blinds-image {
            float: right;
            height: auto;
            width: 50%;
        }
    }

    @include bp1400 {
        .text {
            padding: 40px 75px;
        }
    }
}

.blinds-range-tabs-nav {
    position: relative;

    &:after {
        //content: "";
        //position: absolute;
        //top: 0;
        //right: 0;
        //background: linear-gradient(270deg, #c8d6de, transparent);
        //width: 20px;
        //height: 100%;
    }

    @include bp800 {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        background: $light-grey;
        width: 30%;

        &:after {
            display: none;
        }
    }
}

.blinds-range-tabs-nav a.view-all {
    padding: 22px 30px 18px;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    background: rgba($primary-color, .2);
    float: left;
    width: 100%;
    text-align: right;
    text-decoration: none;
    display: none;
    color: $secondary-color;

    &:hover { background: $secondary-color; color: $blanc; }

    @include bp800 {
        display: block;
    }

    @include bp1000 {
        font-size: 1.8rem;
        padding: 35px 40px 31px;
    }

}

.blinds-range-tabs-nav ul {
    width: 100%;
    position: relative;
    background: $light-grey;

    @include bp800 {
        @include flexbox;
        @include flex-wrap(wrap);
        overflow: hidden;
        white-space: nowrap;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }

        @include IE10 {
          overflow-y: hidden;
        }

        @include edge {
          overflow-y: hidden;
        }

        @include firefox {
          overflow-y: hidden;
        }
    }

    li {
        list-style: none;;
        padding: 17px 10px 13px;
        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 800;
        background: $light-grey;
        border: 1px solid darken($light-grey, 5%);
        border-bottom-color: $light-grey;
        border-left: none;
        @include flex(auto);
        text-align: center;
        transition: .3s;
        cursor: pointer;

        svg {
            display: none;
        }

        &:hover {
            background: darken($light-grey, 4%);
        }

        @include bp800 {
            width: 100%;
            text-align: right;
            padding: 22px 30px 18px;
            border-right: none;

            svg {
                display: inline;
                position: relative;
                top: 2px;
                margin-left: 10px;
            }
        }

        @include bp1000 {
            font-size: 1.8rem;
            padding: 35px 40px 31px;
        }

        @include bp1200 {
            position: relative;
            padding: 43px 110px 39px 40px;

            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 40px;
                width: 45px;
                height: auto;
            }
        }

        &.active {
            @include primaryGradient;
            color: $blanc;
            z-index: 1;
            border-color: $primary-color;

            @include bp800 {
                border-right: none;
            }
        }
    }
}

.blinds-range-tabs-panel {
    display: none;

    @include bp600 {
        //padding: 25px 15px;
    }

    @include bp800 {
        //padding: 4.6vw 5.2vw;
    }

    &.active {
        display: block;

        @include bp800 {
            @include flexbox;
            width: 100%;
        }
    }

    &.copy > * {
        @include bp1200 {
            //margin-bottom: 35px;
        }
    }

    &.copy h4 {
        font-size: 2.5rem;
        font-family: $highlight-font;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $primary-color;
        text-align: center;
        width: 100%;
    }

    p {
        font-size: 1.7rem;
    }

    .gallery {
        padding: 0;

       li {
           float: left;
           width: 49%;
           margin-right: 2%;
           margin-bottom: 2%;
           list-style: none;

           &:nth-child(2n) { margin-right: 0; }
           &:nth-last-child(-n+2) { margin-bottom: 0; }

           @include bp600 {
               width: 23.5%;

               &:nth-child(n) { margin-right: 2%; margin-bottom: 0; }
               &:nth-child(4n) { margin-right: 0; }
           }

           @include bp800 {
            width: 49%;

            &:nth-child(n) { margin-right: 2%; margin-bottom: 2%; }
            &:nth-child(2n) { margin-right: 0; }
            &:nth-last-child(-n+2) { margin-bottom: 0; }
        }
       }

       a {
           display: block;
           overflow: hidden;
       }

       img {
           display: block;
           transition: .3s;

           &:hover {
               transform: scale(1.1);
           }
       }
    }

    .btn {
        padding: 10px 18px;
        font-size: 1.6rem;
        width: 100%;

        @include bp1200 {
            margin-top: 30px;
            width: auto;
            font-size: 1.8rem;
            padding: 12px 30px;
        }
    }
}

ul.benefits {
    margin-left: 0;

    li {
        list-style: none;
        position: relative;
        padding: 10px 10px 10px 50px;
        margin: 5px 0;
        font-size: 1.8rem;

        .icon {
            color: $primary-color;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 6px;
            left: 0;
        }
    }
}

h3.reviews-title {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 50px;
    margin-bottom: 30px;
    color: $secondary-color;
    font-weight: 400;
}

.reviews-carousel {
    display: inline-block;
    width: 100%;
    float: left;
    padding: 10px 20px 50px;
}

.reviews {

    ul {
        list-style: none;
    }

    li {
        padding: 2em;
        width: 100%;
        &:nth-of-type(even){
            background: $light-grey;
        }
    }

    li.see-more-reviews {
        background: $secondary-color!important;
        color: $blanc;
        text-align: center;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(column);
        @include flex-grow(1);

        img {
            max-width: 200px;
        }

        p {
            margin: 20px 0 40px;
            max-width: 400px;
        }

        .btn {
            margin-right: 0;
        }
    }

    .stars {
        display: block;
        color: $conflict-color;
        padding-bottom: 5px;
    }

    h5 {
        font-size: 0.9rem;
        margin-top: 10px;
    }

    @include bp800 {
        ul {
            @include flexbox();
            @include flex-wrap(wrap);
        }
        li {
            width: 50%;
            &:nth-of-type(even){
                background: inherit;
            }
            &:nth-of-type(4n+2),
            &:nth-of-type(4n+3){
                background: $light-grey;
            }
        }
    }

    @include bp1200 {
        ul {
            @include flexbox();
            @include flex-wrap(wrap);
        }
        li {
            width: 25%;
            &:nth-of-type(n){
                background: inherit;
            }
            &:nth-of-type(8n+2),
            &:nth-of-type(8n+4),
            &:nth-of-type(8n+5),
            &:nth-of-type(8n+7)
            {
                background: $light-grey;
            }
        }
    }
}
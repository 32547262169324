/* Numeric Pagination
--------------------------------------------------------------- */

.numeric-pagination {
    width: 100%;
    margin: 60px 0;
    text-align: center;
    li {
        display: block;
        font: 1.8rem/1.2 $primary-font;
        @include flex(1);
        &.active a {
            background: $primary-color;
            color: $blanc;
        }
    }
    a {
        text-decoration: none;
        color: $noir;
        padding: 20px 15px;
        display: block;
        &:hover {
            background: darken($primary-color,20);
            color: $blanc;
        }
    }
}

.numeric-pagination__list {
    width: auto;
    margin: 0 auto;
    @include flexbox();
    @include flex-wrap(wrap);
}

.numeric-pagination__list-item {

}

li.numeric-pagination__direction {
    width: 100%;
    display: block;
    @include flex(auto);
}

.numeric-pagination__direction-next {

    a {
        background: $secondary-color;
        text-align: right;
        color: $blanc;
        &:hover {
            background: darken($secondary-color,20);
        }

        &:after {
            content: '';
            display: inline-block;
            background: url('data:image/svg+xml;utf8,<svg fill="rgb(255,255,255)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M94.35 0l-35.7 35.7L175.95 153 58.65 270.3l35.7 35.7 153-153z"/></svg>') no-repeat;
            background-size: contain;
            background-position: center bottom;
            width: 15px;
            height: 12px;
            display: inline-block;
            margin-left: 10px;
            opacity: .4;
        }

    }
}

.numeric-pagination__direction-prev {

    a {
        background: $secondary-color;
        color: $blanc;
        text-align: left;
        &:hover {
            background: darken($secondary-color,20);
        }

        &:before {
            content: '';
            display: inline-block;
            background: url('data:image/svg+xml;utf8,<svg fill="rgb(255,255,255)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M247.35 270.3L130.05 153l117.3-117.3L211.65 0l-153 153 153 153z"/></svg>') no-repeat;
            background-size: contain;
            background-position: center bottom;
            width: 15px;
            height: 12px;
            display: inline-block;
            margin-right: 10px;
            opacity: .4;
        }

    }
}

.numeric-pagination__ellipses {
    padding: 10px;
}

@include bp1000 {
    .numeric-pagination {
        margin: 60px 0 100px;
    }
    li.numeric-pagination__direction {
        width: auto;
    }
}

// Previous & Next Posts

.previous-next-post {
    text-align: center;
    padding: 20px;

    a {
        font: 2rem/1.2 $primary-font;
        text-decoration: none;
        color: $secondary-color;
        margin-top: 20px;
        display: inline-block;

/*         &:after {
                content: '';
                display: inline-block;
                background: url('data:image/svg+xml;utf8,<svg fill="#{$secondary-color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M94.35 0l-35.7 35.7L175.95 153 58.65 270.3l35.7 35.7 153-153z"/></svg>') no-repeat;
                background-size: contain;
                background-position: center bottom;
                width: 18px;
                height: 15px;
                opacity: .8;
                margin-left: 10px;
                transition: .3s;
        } */

       
    }

    @include bp800 {
        padding: 60px 20px 80px;
    }

    @include bp1200 {
        padding: 80px 20px 120px;

        a {
            font-size: 2.6rem;
        }
    }

}

.previous-next-post__next {
    &:after {
        content: '';
        display: inline-block;
        background: url('data:image/svg+xml;utf8,<svg fill="rgb(91,196,191)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M94.35 0l-35.7 35.7L175.95 153 58.65 270.3l35.7 35.7 153-153z"/></svg>') no-repeat;
        background-size: contain;
        background-position: center bottom;
        width: 18px;
        height: 15px;
        opacity: .8;
        margin-left: 10px;
        transition: .3s;
    }
    &:hover {
        &:after {
            margin-left: 15px;
        }
    }
}

.previous-next-post__previous {
    &:before {
        content: '';
        display: inline-block;
        background: url('data:image/svg+xml;utf8,<svg fill="rgb(91,196,191)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M247.35 270.3L130.05 153l117.3-117.3L211.65 0l-153 153 153 153z"/></svg>') no-repeat;
        background-size: contain;
        background-position: center bottom;
        width: 18px;
        height: 15px;
        opacity: .8;
        margin-right: 10px;
        transition: .3s;
    }
    &:hover {
        &:before {
            margin-right: 15px;
        }
    }
    &:after {
        content: "|";
        margin-left: 20px;
        margin-right: 20px;
        color: darken($light-grey, 15%);
        display: inline;
    }
}
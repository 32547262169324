/*---------------------------------------------------------------
    Tabular List
--------------------------------------------------------------- */

.tabular-list {
	list-style: none;
	margin: 0;
}

.tabular-list__single-item {
	border-bottom: 1px solid $light-grey;
	padding: 10px 0;
}
/*---------------------------------------------------------------
    Areas Covered
--------------------------------------------------------------- */

.radius {
	iframe {
		border: 0;
		min-height: 100%;
	}
}

.areas-covered-local-numbers {
	margin-top: 40px;

	.ld-location {
		margin-bottom: 20px;
	}

	.ld-area {
		color: $secondary-color;
	}

	a {
		text-decoration: none;
	}

	@include bp800 {
		.ld-area {
			float: left;
			margin-right: 10px;
		}
	}

	@include bp1200 {
		.ld-location {
			float: left;
			width: 50%;
			padding: 0 70px 0 0;
		}
		.ld-number {
			float: right;
		}
	}
}

.main-number {
    background: rgba($primary-color, .2);
    text-align: center;
    padding: 30px 20px 20px;
    margin: 20px 0 40px;
    float: left;
    width: 100%;

    p {
    	font-size: 1.8rem;
    	text-transform: uppercase;
    	letter-spacing: 1px;
    	margin: 0;
    }

    a {
    	font-family: $highlight-font;
    	font-size: 3rem;
    	color: $tertiary-color;
    	text-decoration: none;
    }

    @include bp800 {
    	margin-bottom: 0;
    }

    @include bp1200 {
    	padding: 45px 20px 40px;
    	a {
    		font-size: 4rem;
    	}
    }
}

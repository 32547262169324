/*---------------------------------------------------------------
    Copy
--------------------------------------------------------------- */
main.text-wrap {
  .single-map {
    border:0;
    min-height: 200px;
    padding-bottom: 20px;
  }

  .single-map-image,
  .single-map-bawtry,
  .single-map-rossington {
    display: none;
    display: none;
  }

  // @include bp800 {
    // padding-bottom: 0;
  // }

  @include bp1000 {
    @include flexbox;
  }

  @include bp1200 {
    .single-locations & {
      @include align-items(center);
    }

    .single-map {
      display: none;
      z-index: -1;
    }

    .single-map-bawtry {
      background: url("../images/single-map-bawtry.jpg");
      height: 1110px;
    }

    .single-map-rossington {
      background: url("../images/single-map-rossington.jpg");
      height: 1400px;
    }

    .single-map-image {
      background-position: center center;
      display: block;
      width: 100%;
    }
  }

  @include bp1400 {
    .single-map-bawtry {
      height: 1090px;
    }
  }

  @include bp1600 {
    .single-map-bawtry {
      height: 850px;
    }

    .single-map-rossington {
      height: 1200px;
    }
  }

  @include bp(1700) {
    .single-map-rossington {
      height: 1100px;
    }
  }
}

.copy-image {
  display: none;
  @include bp1000 {
    display: block;
    width: 50%;
    z-index: -1;

    &.internal {
      width: 25%;
    }
  }
  @include bp1200 {
    width: 60%;
  }

  .page-id-837 & {
    display: none;
  }
}

.site-content {
  @include bp1000 {
    float: right;
    width: 50%;

    &.wide {
      width: 100%;
      float: none;
    }
  }

  .page-id-837 & {
    @include bp1000 {
      width: 66%;
    }
  }

  .cookie-policy &,
  .error404 & {
    float: unset;
    max-width: 1600px;
    width: 100%;

    .copy--internal {
      width: 100%;
    }
  }

  .error404 & {
    .copy {
      overflow: auto;
      padding: 50px 0;
      width: 100%;
    }
  }
}

.copy {
  h1 {
    color: $secondary-color;

    span {
      color: $primary-color;
    }
  }

  h2 {
    color: $primary-color--dark;
  }

  h1 + p {
    font-size: 2rem;
    line-height: 1.4;
  }

  p {
    margin: 20px 0;

    a {
      color: $primary-color;
    }
  }

  ul {
    margin: 20px 0;
    padding: 0 20px;
  }

  ul:not(.benefits):not(.tabular-list) {
    li {
      padding: .5rem 0;
    }

    @include bp600 {
      @include flexbox;
      @include flex-wrap(wrap);

      li {
        width: 50%;
      }
    }

    @include bp1000 {
      li {
        width: 100%;
      }
    }

    @include bp1600 {
      li {
        width: 50%;
      }
    }
  }

  ol {
    margin-left: 10px;
  }

  a {
    color: $primary-color;

    &:hover {
      color: $secondary-color--dark;
    }
  }

  iframe {
    border:0;
    // min-height: 200px;
  }
}

.copy--home {
  @include bp800 {
    padding: 40px;
    float: right;
  }
  @include bp1200 {
    max-width: 800px;
    padding: 60px 50px;
    margin: 70px 50px 70px -100px;
    background: $blanc;
  }
  @include bp1400 {
    margin-right: 100px;
  }
}

.copy--internal {
  padding: 20px;
  @include bp800 {
    padding: 40px;
    float: right;
  }
  @include bp1200 {
    max-width: 1000px;
    padding: 60px 50px;
    background: $blanc;
  }
}

.post-wrap {
  float: left;
  padding: 0 20px;
  @include bp600 {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
  }

  .post-list-item {
    float: left;
    width: 100%;
    // @include flexbox;
    @include bp600 {
      width: 49%;
    }
  }
}

.copy--news {
  width: 100%;

  > img:first-child {
    float: left;
    @include bp800 {
      margin-top: 0;
    }
  }

  h1 + p {
    font-size: inherit;
    font-weight: normal;
  }

  p {
    a {
      color: $primary-color;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .single-news-item {
    text-decoration: none;
    padding: 30px;
    margin: 0 0 20px;
    border: 1px solid $light-grey;
    // display: block;
    float: left;
    width: 100%;

    h1 {
      font-size: 2rem;

      @include bp600 {
        font-size: 2.2rem;
      }

      @include bp1200 {
        font-size: 2.6rem;
      }
    }

    p {
      color: $noir;
    }

    .btn {
      font-size: .95em;
      margin: 0 auto;
      width: 100%;

      @include bp600 {
        font-size: 1em;
      }
    }

    &:after {
      content: '';
      clear: both;
      display: block;
    }

    &:hover {
      background: $light-grey;

      h1 {
        color: $primary-color--dark;
      }

      .single-news-item__image {
        filter: contrast(1.2);
      }
    }
  }

  .single-news-item__date {
    font-size: 0.8em;
    color: $tertiary-color;
    margin-bottom: 20px;
    display: block;
  }

  .single-news-item__image {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 20px;
  }

  .single-news-item__image--copy {
    width: 100%;
    display: block;
    height: auto;
    margin: 20px 0;
    @include bp1200 {
      margin: 20px 0 40px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  @include bp480 {
    img {
      width: auto;
    }

    .alignleft {
      float: left;
      margin: 10px 10px 10px 0;
    }

    .aligncenter {
      clear: both;
      margin: 0 auto;
    }

    .alignright {
      float: right;
      margin: 10px 0 10px 10px;
    }
  }
}

p.general-testimonial {
  padding: 20px;
  background: $tertiary-color;
  color: $blanc;
  font-size: 1.8rem;
  font-family: $highlight-font;
  position: relative;
  padding-left: 80px;
  margin: 30px 0;

  &:before {
    content: open-quote;
    font-family: Georgia;
    position: absolute;
    top: -20px;
    left: 0;
    line-height: 130px;
    z-index: 10;
    font-size: 17rem;
    opacity: 0.6;
    color: $tertiary-color--light;
  }

  span {
    font-family: $primary-font;
    font-weight: 700;
    display: block;
    opacity: 0.6;
    margin-top: 20px;
  }
}

.cta-bottom {
  background: $blanc;
  border: 1px solid $light-grey;
  padding: 30px 40px 40px;
  float: left;
  width: 100%;
  margin: 30px 0;

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      color: $secondary-color;
    }
  }

  .ld-area {
    color: $secondary-color;
  }

  .ld-phonenumber {
    display: block;
    font-family: $highlight-font;
    font-size: 2.6rem;
    color: $secondary-color;

    a {
      white-space: nowrap;
      text-decoration: none;
    }
  }

  .ld-location {
    width: 50%;
    float: left;
    padding-right: 20px;
    margin-top: 20px;
  }
}

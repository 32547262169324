.our-services {
  padding: 1.6rem 0;

  &__info {
    @include flex-direction(column);
  }

  &__text {
    background: lighten($primary-color--light, 35%);
  }

  &__image {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    background: lighten($secondary-color--light, 50%);
    padding: 2.4rem;

    img {
      max-width: 170px;
      width: 170px;
    }
  }

  &__text {
    padding: 2.4rem;
  }

  @include bp600 {
    &__info {
      @include flex-direction(row-reverse);

      &:nth-of-type(odd) {
        @include flex-direction(row);
      }
    }

    &__text {
      width: 50%;
    }

    &__image {
      width: 50%;
    }
  }

  @include bp1000 {
    &__info {
      @include flex-direction(column);
    }

    &__text, &__image {
      width: 100%;
    }
  }

  @include bp1200 {
    &__info {
      @include flex-direction(row-reverse);
    }

    &__text {
      width: 50%;
    }

    &__image {
      width: 50%;
    }
  }
}

/*---------------------------------------------------------------
	Benefits list
--------------------------------------------------------------- */

.benefits-list {

	.title {
		font-size: 2.6rem;
		font-family: $highlight-font;
	}

	ul {
		list-style: none;
	}

	li {
		margin: 5px 0;
		font-size: 2rem;
	}

	svg {
		color: $secondary-color;
		margin-top: 4px;
	}
}


.benefits-list--item {
	display: inline-block;
	width: 100%;
	padding-left: 10px;
}

.benefits-list--center-800 {
	@include bp800 {
		text-align: center;
	}
}

.benefits-list--bold {
	padding: 20px 0;
	ul {
		font-size: 1.2em;
	}
}

.benefits-list-h2--highlight {
	color: $primary-color;
}
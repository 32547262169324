
/*---------------------------------------------------------------
    Bucket Grid
--------------------------------------------------------------- */

/* Strip Unit from SASS Variable (requried for Buckets Grid)
--------------------------------------------------------------- */

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}


/* Bucket Grid
--------------------------------------------------------------- */

$bucketMargins : 2%;
$marg : ($bucketMargins / 2);
$bucketMargins : strip-unit($bucketMargins);

.buckets {

    max-width: $full-width;
    margin: 0 auto;
    list-style: none;
    @include flexbox();
    @include flex-direction(column);

    li {
        width: 100%;
        background-size: cover;
        background-position: center;
        transition: .3s;
        margin-bottom: 2%;
        &:hover {
            filter: contrast(1.2);
        }
    }

    @include bp480 {

        @include flex-direction(row);
        @include flex-wrap(wrap);

        li {
            width: 49%;
            margin: 0 1% 2%;
        }

        li:nth-of-type(odd) {
            margin-left: 0;
        }

        li:nth-of-type(even) {
            margin-right: 0;
        }
    }

    @include bp800 {


        @for $i from 1 through 8 {
            $marginsTotal : (($bucketMargins * $i) - $bucketMargins); // Multiply margins by no. of buckets and minus margins for first/last buckets = This equals the total % of all bucket margins
            $spaceRemaining : (100 - $marginsTotal); // 100% minus the total % taken up by the bucket margins
            $width: (percentage(($spaceRemaining / $i) / 100));
            &.num-#{$i} li {
                width: $width;
                margin: 0 $marg;
            }
        }
    }

    li:first-of-type {
        margin-left: 0;
    }

    li:last-of-type {
        margin-right: 0;
    }

    img {
        width: auto;
        max-width: 300px;
        height: auto;
        display: block;
    }

    a {
        display: block;
        color: $blanc;
        padding: 0 0 180px 0;
        height: 100%;
        min-height: 180px;
        color: $blanc;
        position: relative;
    }

    .title {
        background: $primary-color;
        padding: 10px;
        display: inline-block;
    }

    .offer {
        position: absolute;
        z-index: 100;
        bottom: 0;
        right: 0;
        padding: 10px;
        color: $noir;
        text-align: right;
        span {
            display: block;
            font-family: $highlight-font;
            font-size: 2.5rem;
        }
    }
    .triangle {
        z-index: 10;
        content: '';
        border-bottom: 180px solid $conflict-color;
        border-left: 180px solid transparent;
        position: absolute;
        z-index: 100;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
    }
}

.lazyload-wistia {
  // display: none;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;

  @include bp800 {
    display: block;
  }
}

/*---------------------------------------------------------------
	Logos / Brands
--------------------------------------------------------------- */

.logos-brands {
	text-align: center;
	border-left: none;
	border-right: none;
	h2 {
	    display: inline-block;
	    width: 100%;
	    text-align: center;
	    font-size: 2.5rem;
	    line-height: 1.3;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    margin-top: 20px;
	    margin-bottom: 30px;
	    color: $secondary-color;
	}
	ul {
		list-style: none;
	}
	li {
		display: inline-block;
	}
	img {
		display: block;
		padding: 10px;
		height: auto;
		width: auto;
		max-width: 150px;
		max-height: 60px;
	}

	@include bp1200 {
		padding: 30px 0;

		img {
			margin: 0 15px;
			max-width: 180px;
			padding: 5px;
		}
	}
}
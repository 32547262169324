.blog {
  // Tweak layout for news page/sidebar prior to fix on template
  .news-aside-outer {
    overflow: auto;
    padding: 0 20px;

    @include bp1200 {
      padding: 0 0 0 20px;
    }
  }
}

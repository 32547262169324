.credit-cards {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    padding: 5px;
    img {
        max-width: 60px;
        height: auto;
        width: 100%;
        display: inline-block;
        margin-right: 10px;
    }
    @include bp800 {
        @include justify-content(flex-start);
    }
}

/*---------------------------------------------------------------
    Footer
--------------------------------------------------------------- */
// Primary
#navigation--footer {
  background: $light-grey;
  text-align: center;
  padding: 0 0 20px;

  a {
    text-decoration: none;

    &:hover {
      color: $primary-color;
    }
  }

  .sub-menu {
    display: none;
  }
  @include bp800 {
    padding: 0;
    display: block;
    text-align: left;

    li {
      display: inline;
      float: left;
      width: 50%;
    }

    .current-menu-item a,
    .current-menu-parent a {
      border-color: $primary-color;
    }

    a {
      display: inline-block;
      padding: 5px 0;
      position: relative;
    }

    .sub-menu {
      display: none;
    }
  }
}

footer {
  background: $light-grey;
  clear: both;
  overflow: hidden;
  text-align: center;
  color: $secondary-color;

  ul {
    list-style: none;
  }

  li {
    display: block;
  }

  img {
    max-width: 100%;
  }

  .col {
    float: left;
    width: 100%;

    &.social-logos {
      .google-review {
        background: none;
        color: $primary-color;
        display: block;
        margin: 1em 0;
        font-size: 1.1em;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  .pad-30-30 {
    padding: 0;
  }

  @include bp800 {
    text-align: left;

    .col {
      width: 50%;
      padding: 20px;
      &.copyright {
        width: 100%;
      }
    }

    .grid {
      padding: 20px;

      &12_12 {
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-between);
      }
    }
  }

  @include bp1200 {
    .col {
      &.copyright {
        width: 25%;
        // width: 33.33333%;
      }

      &.navigation {
        width: 30%;
      }

      &.contact {
        width: 25%;
        // width: 16%;
      }

      &.social-logos {
        width: 20%;

        .btn {
          padding: 15px 20px 11px;
        }
      }
    }
  }

  @include bp1400 {
    .col {
      &.social-logos {
        width: 20%;

        .btn {
          padding: 15px 30px 11px;
        }
      }
    }
  }

  h2 {
    margin: 20px 0;
    font-size: 1.8rem;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $primary-color;
  }

  a {
    color: $secondary-color;

    &:hover {
      color: $primary-color;
    }
  }

  .adtrak img {
    max-width: 40px;
    opacity: 0.4;
    margin-top: 50px;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  .social-logos {
    a {
      color: $blanc;
      background-color: $secondary-color;

      &:hover {
        // color: $secondary-color--dark;
      }
    }

    svg {
      margin: 3px;
    }
  }
}
/* Cookies
--------------------------------------------------------------- */
#wp-notification {
  background: rgba($tertiary-color, 0.85);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  display: none;
}

#wp-notification.open {
  display: block;
}

#wp-notification .wp-notification-container {
  margin: 0 auto;
  color: #fff;
  max-width: 1280px;
  text-align: center;
  padding: 10px 0;
}

#wp-notification .wp-notification-container p {
  margin: 0;
  text-transform: capitalize;
}

#wp-notification .wp-notification-container p a {
  color: $blanc;
  display: inline-block;
  padding: 3px 5px;
  margin-left: 5px;
}

#wp-notification .wp-notification-container p a:hover {
  background-color: $primary-color;
}

#wp-notification-toggle {
  color: $blanc;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 3px 5px;
  margin-left: 5px;
  border: 1px solid $blanc;
  text-transform: capitalize;
  display: inline-block;

  &:hover {
    background-color: $primary-color;
  }
}

#wp-notification-toggle p:hover {
  color: #dcdcdc;
  cursor: pointer;
}
/* Back to top
--------------------------------------------------------------- */
#back-top {
  position: fixed;
  cursor: pointer;
  right: 0;
  bottom: 0;
  z-index: 300;
  opacity: 0.4;
  text-align: center;
  background: $primary-color;
  padding: 3px;
  margin: 0;

  &:hover {
    opacity: 1;
    transition: 0.3s;
  }

  a {
    color: $blanc;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 2px;
    padding: 5px 15px;
    display: block;

    i {
      display: none;
    }

    svg {
      display: block;
      font-size: 4rem;
    }
  }
}

.reviews {
  @include flexbox;
  @include flex-wrap(wrap);
  padding: 3.2rem 0 0 0;

  &__content {
    @include flex-direction(column);
    padding: 3.2rem;

    &:nth-of-type(odd) {
      background-color: lighten($secondary-color, 63%);
    }

    &:nth-of-type(even) {
      background-color: lighten( $primary-color, 50%);
    }

    &--name {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  @include bp1000 {
    &__content {
      width: 25%;

      &:nth-of-type(n) {
        background-color: lighten($secondary-color, 63%);
      }

      &:nth-of-type(10n+2),
      &:nth-of-type(10n+4),
      &:nth-of-type(10n+5),
      &:nth-of-type(10n+7),
      &:nth-of-type(10n+10) {
        background-color: lighten( $primary-color, 50%);
      }
    }
  }
}

/*---------------------------------------------------------------
    Page USPs
--------------------------------------------------------------- */

.page-usps {
	display: inline-block;
	width: 100%;
	margin-bottom: 40px;

	li {
		list-style: none;
		font-weight: 400;
		margin-bottom: 40px;
		text-align: center;

		p {
			font: 1.8rem/1.2 $primary-font;
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		&:last-of-type { margin-bottom: 0; }

		span {
			display: block;
			font-weight: 800;
		}

		svg {
			color: $primary-color;
			width: 65px;
			height: 65px;
			margin-bottom: 20px;
		}
	}

	@include bp480 {
		// margin-bottom: 0;
		// margin-top: 10px;
		margin: 10px 0;

		ul {
			@include flexbox;
			@include flex-wrap(wrap);
		}

		li {
			float: left;
			width: 50%;
		}
	}

	@include bp800 {
		.commercial-blinds & {
			li {
				width: 25%;
			}
		}
	}

	@include bp1000 {
		margin: 20px 0 0;

		ul {
			@include justify-content(space-around);
		}

		li {
			width: auto;
		}

		.commercial-blinds & {
			li {
				width: auto;
			}
		}
	}

	@include bp1200 {
		.home & {
			padding: 20px 0 40px;
		}

		li {
			svg {
				display: block;
				margin: 0 auto;
			}
		}

		.commercial & {
			li {
				text-align: center;
				margin-bottom: 0;

				svg {
					float: none;
					display: block;
					margin: 0 auto 20px;
				}
			}
		}
	}
	@include bp1400 {
		li {
			text-align: left;

			svg {
				float: left;
				margin: 0 20px 0 0;
			}

			p {
				float: left;
				font-size: 2rem;
			}
		}
	}
}

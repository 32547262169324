/*---------------------------------------------------------------
    Buttons
--------------------------------------------------------------- */

.btn {
    display: inline-block;
    padding: 15px 10px 11px;
    color: $noir;
    text-decoration: none; 
    transition: .3s;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;

    @include bp480 {
        padding: 15px 30px 11px;
        width: auto;
    }

    @include bp800 {
        margin-right: 30px;
    }
    
    &:hover {
        background: $noir;
        color: $blanc;
        border-color: $blanc;
        text-decoration: none;
    }

    // Define the 1) button name, 2) text/border color & 3) background color
    $btnColors :        noir $noir,
                        blanc $blanc,
                        primary-color $primary-color;

    // Loop through colours above and create classes
    @each $colorOption in $btnColors {
        &--#{nth($colorOption, 1)} {
            color: nth($colorOption,2);
            border-color: nth($colorOption,2);
        }
    }

}

.btn--gradient {
    background: $primary-color;
    @include primaryGradient;
    color: $blanc!important;
}

.btn--clear {
    box-shadow: inset 0 0 0 3px $secondary-color;
    color: $secondary-color;
}

.btn--full-width {
    width: 100%;
    display: block;
}

.btn--link {
    background: $blanc;
    display: block;

    &:hover {
        background-color: $primary-color;
    }

    @include bp480 {
        display: inline-block;
    }
}
/*---------------------------------------------------------------
    Child Buckets
--------------------------------------------------------------- */

.child-buckets {
	margin: 20px 0;
	@include justify-content(space-between);
	float: left;
	width: 100%;

	@include bp1200 {
		margin-top: 50px;
	}
}

.child-buckets__single-item {
	width: 100%;
	position: relative;
	border: 10px solid $blanc;
	box-shadow: 0 1px 15px rgba($noir, .1);
	margin-bottom: 20px;

	.title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 10;
		background: $light-grey;
		padding: 10px;
		color: $primary-color;
		transition: .3s;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	.offer {
		font-size: 0.8rem;
		text-transform: uppercase;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		text-align: center;
		z-index: 100;
		line-height: 1.3rem;
		color: $blanc;
		padding: 5px 10px;
		background: $secondary-color;

		span {
			font-size: 1.8rem;
			color: $primary-color;
		}
	}
    .triangle {
        z-index: 1;
        content: '';
        border-bottom: 100px solid $secondary-color;
        border-left: 100px solid transparent;
        position: absolute;
        z-index: 100;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
    }
    &:hover {
    	filter: contrast(1.2);
		box-shadow: 0 1px 15px rgba($noir, .3);

    	.title {
    		background: $primary-color;
    		color: $blanc;
    	}
    }
    @include bp800 {
    	margin-bottom: 40px;
    	width: 48%;

		.offer {
			font-size: 1.6rem;
			text-transform: none;
			line-height: 2.4rem;
			span {
				font-size: 2.4rem;
				letter-spacing: -2px;
			}
		}
	    .triangle {
	        border-bottom: 160px solid $secondary-color;
	        border-left: 160px solid transparent;
	    }
    }
    @include bp1200 {
    	width: 31.5%;
    }

    @include bp1400 {
    	width: 24%;
    	margin-bottom: 25px;
    }
}
/*---------------------------------------------------------------
    Contact
--------------------------------------------------------------- */

.contact-address {
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 10px;
	position: relative;
	padding: 0 0 0 20px;
	float: left;
	width: 100%;

	svg {
		color: $primary-color;
		position: absolute;
		top: 4px;
		left: 0;
	}
}
/*---------------------------------------------------------------
    Sidebar
--------------------------------------------------------------- */

.sidebar-cta {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	float: left;
	width: 100%;
	padding: 40px;
}

.sidebar-cta--visualise-your-room {
	@include bp1200 {
		background-size: auto;
		background-position: center right;
		background: darken($noir, 15%);
		background-repeat: no-repeat;
	}
}

.sidebar-cta--border-light-grey {
	border: 1px solid $light-grey;
}

.sidebar-cta__image {
	width: 100%;
	height: auto;
}

.sidebar-cta__title {
	font-family: $highlight-font;
	font-size: 1.8rem;
	display: block;
	letter-spacing: 1px;
	text-transform: uppercase;

	span {
		display: block;
		font-family: $primary-font;
	}
}

.sidebar-cta__title-primary-color {
	color: $primary-color;
}

.sidebar-cta__title--button {
	display: block;
	background: $primary-color;
	padding: 5px;
	transition: .3s;
	color: $blanc;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.sidebar-cta__full-link {
	padding: 180px 20px 20px 20px;
	display: block;
	color: $blanc;
	text-decoration: none;
	font-family: $highlight-font;
	font-size: 1.5em;
	transition: .3s;
	&:hover {
		.sidebar-cta__title--button {
			background: $secondary-color--dark;
		}
	}
}

.sidebar-cta--icon {
	color: $blanc;
}

.box--light-grey {
	.sidebar-cta--icon {
		color: $secondary-color;
	}
}

.sidebar-cta--icon-fixed-right {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px;
	width: 60px;
	height: 60px;
}

.sidebar-cta--icon-fixed-left {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 30px;
	width: 60px;
	height: 60px;

	svg {
		width: 100%;
		height: 60px;
	}
}

.sidebar-cta--left-icon {
	padding-left: 120px;
}

.news-aside {
	background: rgba($light-grey, .6);

	.list {
		border-bottom: 1px solid $light-grey;
		padding-bottom: 10px;
		margin-bottom: 10px;

		&:last-of-type {
			border: 0;
			margin-bottom: 0;
		}
	}

	ul {
		list-style: none;
	}
	li {
		margin: 3px 0;
	}
	a {
		color: $primary-color;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.news-aside-outer {
	overflow: auto;
	
	@include bp800 {
		float: right!important;
	}
}

/*---------------------------------------------------------------

	Variables

--------------------------------------------------------------- */

/* Colors

--------------------------------------------------------------- */

$blanc: #fff;
$noir: #333;
$light-grey: #edf4f9;
$mid-grey: #ccc;
$dark-grey: #777777;

// The $primary-color is the most used colour, and should be the prominent colour of their branding
// Better with real colours (i.e. not black, white, grey, dark or light shades)
// Better as a colour that white can sit comfortably on
$primary-color: #1a8fce;
$primary-color--dark: darken($primary-color,10);
$primary-color--light: lighten($primary-color,10);

// $secondary-color needs to work well with the primary, but also be different enough to stand alone
// Better as a colour that white can sit comfortably on
$secondary-color: #4f5661;
$secondary-color--dark: darken($secondary-color,10);
$secondary-color--light: lighten($secondary-color,10);

// Great as a more relaxed colour, not too aggressive, for subtle highlights and content pieces.
// Better as a colour that white can sit comfortably on
$tertiary-color: #0A374F;
$tertiary-color--dark: darken($tertiary-color,10);
$tertiary-color--light: lighten($tertiary-color,10);

// The "highlight" colour - used to really stand out against everything else
// Probably doesn't belong in the normal colour palette at all
$conflict-color: #CF801B;
$conflict-color--dark: darken($conflict-color,10);
$conflict-color--light: lighten($conflict-color,10);
/* Gradient
--------------------------------------------------------------- */
@mixin primaryGradient {
	background: linear-gradient(97.47deg, $primary-color 0%, $secondary-color 100%);
}
/* Sizes
--------------------------------------------------------------- */
$x-small-width: 480px;
$small-width: 800px;
$medium-width: 1000px;
$large-width: 1200px;
$x-large-width: 1400px;
$full-width: 1600px;
/* Fonts
--------------------------------------------------------------- */
$primary-font: "Montserrat", sans-serif;
$highlight-font: "Montserrat Bold", sans-serif;
/* Anything else
--------------------------------------------------------------- */
$mobile-top-bar-height: 40px;
